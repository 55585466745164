import React, { useState, useEffect } from 'react';

import { getMoviesUrl } from "../../helpers/url-helper";
import useAxiosHelper from '../customHooks/useAxiosHelper';

import MovieCard from "./MovieCard";

function Movies(props) {
   const [movies, setMovies] = useState([]);
   const [moviesLoading, setMoviesLoading] = useState(false);
   const { getAsync } = useAxiosHelper();

   useEffect(() => {
      const fetchData = async () => {
         try {
            setMoviesLoading(true);
            const data = await getAsync(getMoviesUrl(), true);
            setMovies(data);
         } catch (error) {
            console.error('Error fetching movies:', error);
         } finally {
            setMoviesLoading(false);
         }
      };

      fetchData();
   }, [getAsync]);

   return (
      <div className="bg-white py-5 sm:py-5">
         <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
               <h2 className="text-3xl text-gray-600 font-bold font-sans tracking-tight sm:text-4xl">Quote of the day</h2>
               <p className="mt-2 text-lg leading-8 text-gray-600 italic">`Are you not entertained? Are you not entertained? Is this not why you are here?`</p>
               <p className="mt-2 text-lg leading-8 text-gray-600 text-right">Maximus</p>
            </div>
            {moviesLoading && <div>Loading movies...</div>}
            {movies && (
               <div className="mx-auto mt-10 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 md:mx-0 md:max-w-none sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                  {movies.map((movie, index) => (
                     <MovieCard key={index} movie={movie} />
                  ))}
               </div>
            )}
         </div>
      </div>
   )
}

export default Movies;