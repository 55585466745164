import React from 'react';

import Navbar from "./Navbar";

function Header() {
   return (
      <header>
         <Navbar />
      </header>
   );
}

export default Header;