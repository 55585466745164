import * as Yup from 'yup';

const MovieSchema = Yup.object().shape({
   title: Yup.string().required('Title is required'),
   releaseYear: Yup.number()
      .typeError('Release year must be a number')
      .required('Release year is required')
      .min(1850, "Release year must be valid")
      .max(new Date().getFullYear()),
   duration: Yup.number()
      .typeError('Duration must be a number')
      .required('Duration is required')
      .min(1, "Duration must be at least 1 minute")
      .integer("Duration must be a whole number"),
   scoreRottenTomatoes: Yup.number()
      .typeError('Score must be a number')
      .required('Score is required')
      .min(1, "Duration must be at least 1 minute")
      .integer("Duration must be a whole number"),
   genre: Yup.string().required('Genres are required'),
   directors: Yup.string().required('Directors are required'),
   actors: Yup.string().required('Actors are required'),
});

export default MovieSchema;