import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import NavList from "./NavList";

import Page from "../constants/application-page";
import Device from "../constants/device-type";

function Navbar() {

   const { pathname } = useLocation();
   const [isMenuOpen, setIsMenuOpen] = useState(false);

   return (
      <nav className="border-b-2">
         <div className="mx-auto max-w-7xl px-4 md:px-6">
            <div className="flex h-16 justify-between">
               <div className="flex">
                  <div className="-ml-2 mr-2 flex items-center md:hidden">
                     <button onClick={() => setIsMenuOpen(!isMenuOpen)} type="button" className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
                        <span className="absolute -inset-0.5"></span>
                        <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                           <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                        <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                           <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                     </button>
                  </div>
                  <NavList model={{ device: Device.DESKTOP }} />
               </div>
               {pathname.includes("movies") && (
                  <div className="flex items-center">
                     <div className="flex-shrink-0">
                        <a href={Page.NEWMOVIE} className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                           <svg className="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                           </svg>
                           Add movie
                        </a>
                     </div>
                  </div>
               )}
            </div>
         </div>
         <NavList model={{ device: Device.MOBILE, isMenuOpen: isMenuOpen }} />
      </nav>
   );
}

export default Navbar;

