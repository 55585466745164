import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import FormikInput from '../FormikInput';
import MovieSchema from '../schemas/movieSchema';

function MovieForm({ movie, onSubmit, onDelete }) {

   const [selectedImage, setSelectedImage] = useState(null);

   const title = movie ? "Movie details" : "Add new movie";
   const initialValues = {
      title: movie?.title || '',
      genre: movie?.genre?.join(", ") || '',
      directors: movie?.directors?.join(", ") || '',
      actors: movie?.actors?.join(", ") || '',
      releaseYear: movie?.releaseYear || '',
      duration: movie?.duration || '',
      scoreRottenTomatoes: movie?.scoreRottenTomatoes || '',
   };

   return (
      <Formik
         initialValues={initialValues}
         validationSchema={MovieSchema}
         onSubmit={(values) => {
            onSubmit(values, selectedImage);
            setSelectedImage(null);            
         }}
      >
         {({ errors, touched, isSubmitting }) => (
            <Form>
               <div className="space-y-12">
                  <div className="">
                     <h2 className="text-base font-semibold leading-7 text-gray-900">{title}</h2>
                     <p className="mt-1 text-sm leading-6 text-gray-600">Any changes saved here will be updated in the database</p>

                     {errors && touched && (
                        <div className="mt-10 error-summary">
                           <ul>
                              {Object.keys(errors).map(fieldName => (
                                 <li key={fieldName}>{errors[fieldName]}</li>
                              ))}
                           </ul>
                        </div>
                     )}

                     <div className={`mt-${!errors ? "10" : "5"} grid gap-x-6 gap-y-3 sm:grid-cols-8`}>
                        <div className="sm:col-span-6">
                           <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">Title</label>
                        </div>
                        <FormikInput name="title" className="sm:col-span-6" />
                        <div className="sm:col-span-6">
                           <label htmlFor="genre" className="block text-sm font-medium leading-6 text-gray-900">Genres</label>
                        </div>
                        <FormikInput name="genre" className="sm:col-span-6" />
                        <div className="sm:col-span-6">
                           <label htmlFor="directors" className="block text-sm font-medium leading-6 text-gray-900">Directors</label>
                        </div>
                        <FormikInput name="directors" className="sm:col-span-6" />
                        <div className="sm:col-span-6">
                           <label htmlFor="actors" className="block text-sm font-medium leading-6 text-gray-900">Actors</label>
                        </div>
                        <FormikInput name="actors" className="sm:col-span-6" />
                     </div>
                     <div className="mt-3 grid gap-x-6 gap-y-3 sm:grid-cols-8">
                        <div className="sm:col-span-2">
                           <label htmlFor="releaseYear" className="block text-sm font-medium leading-6 text-gray-900">Release Year</label>
                           <FormikInput name="releaseYear" className="mt-2" />
                        </div>
                        <div className="sm:col-span-2">
                           <label htmlFor="duration" className="block text-sm font-medium leading-6 text-gray-900">Duration</label>
                           <FormikInput name="duration" className="mt-2" />
                        </div>
                        <div className="sm:col-span-2">
                           <label htmlFor="scoreRottenTomatoes" className="block text-sm font-medium leading-6 text-gray-900">Score</label>
                           <FormikInput name="scoreRottenTomatoes" className="mt-2" />
                        </div>
                     </div>
                     <div className="mt-3 grid gap-x-6 gap-y-3 sm:grid-cols-8">
                     </div>
                  </div>
               </div>
               <div className="grid gap-x-6 sm:grid-cols-8">
                  <div className="border-b border-gray-900/10 pb-12 col-span-6"></div>
               </div>
               <div className="mt-6 grid gap-x-6 sm:grid-cols-8">
                  <div className="mr-auto sm:col-span-3">
                     <label className="block">
                        <span className="sr-only">Choose profile photo</span>
                        <input
                           type="file"
                           accept=".png, .jpg, .jpeg"
                           onChange={(e) => setSelectedImage(e.target.files[0])}
                           className="block w-full text-sm text-slate-500 cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100" />
                     </label>
                  </div>
                  <div className='grid grid-flow-col justify-stretch sm:col-span-3 sm:flex sm:justify-end items-center gap-x-6 mt-6 sm:mt-0'>
                     {movie && <button type="button" onClick={() => onDelete(movie.id)} className="sm:col-span-2 bg-red-500 hover:bg-red-600 text-sm text-white font-semibold py-2 px-4 rounded">Delete</button>}
                     <button type="submit" disabled={isSubmitting} className="sm:col-span-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                  </div>
               </div>
            </Form>
         )}
      </Formik>
   );
}

export default MovieForm;