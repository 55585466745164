import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getMovieByIdUrl } from "../../helpers/url-helper";
import MovieForm from './MovieForm';
import useUploadImage from '../customHooks/useUploadImage';
import useAxiosHelper from '../customHooks/useAxiosHelper';

function MovieDetails(props) {
   const { id } = useParams();
   const [movie, setMovie] = useState(null);
   const [imageUrl, setImageUrl] = useState(null);
   const [isDeleted, setIsDeleted] = useState(false);
   const { getAsync, putAsync, deleteAsync } = useAxiosHelper();
   const { uploadImage, downloadImage } = useUploadImage();

   useEffect(() => {
      const fetchData = async () => {
         try {
            const data = await getAsync(getMovieByIdUrl(id));
            setMovie(data);
         } catch (error) {
            console.error('Error fetching movie:', error);
         }
      };

      fetchData();
   }, [id, getAsync]);

   useEffect(() => {
      const fetchImageUrl = async () => {
         if (movie && movie.id) {
            try {
               // Get pre-signed url
               const preSignedUrl = await downloadImage(movie.id);
               setImageUrl(preSignedUrl);
            } catch (error) {
               console.error('Error fetching movie cover image:', error);
            }
         }
      };

      fetchImageUrl();
   }, [movie, downloadImage]);

   const handleSubmit = async (values, selectedImage) => {
      try {
         const movieData = {
            ...values,
            genre: values.genre.split(', '),
            directors: values.directors.split(', '),
            actors: values.actors.split(', ')
         };

         await putAsync(getMovieByIdUrl(id), movieData);
         setMovie(movieData);

         if (selectedImage) {
            await uploadImage(selectedImage, id);
         }
      } catch (error) {
         console.error('Error updating movie:', error);
      }
   };

   const handleDelete = async () => {
      try {
         await deleteAsync(getMovieByIdUrl(id));
         setIsDeleted(true);
      } catch (error) {
         console.error('Error deleting movie:', error);
      }
   };

   if (isDeleted) {
      return <div>Movie Deleted Successfully</div>;
   }

   return (
      <>
         {/*movie && <MovieForm movie={movie} onSubmit={handleSubmit} onDelete={handleDelete} />*/}

         {movie && (
            //<article className="relative isolate flex flex-col gap-8 lg:flex-row">
            //   <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            //      {imageUrl && <img src={imageUrl} alt="" className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />}
            //      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
            //   </div>
            //   <div>
            //      <div className="flex items-center gap-x-4 text-xs">
            //         <time datetime="2020-03-16" className="text-gray-500">Mar 16, 2020</time>
            //         {movie.genre.map(genre => {
            //            return <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{genre}</span>
            //         })}
            //      </div>
            //      <div className="group relative max-w-xl">
            //         <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
            //            <a href="#">
            //               <span className="absolute inset-0"></span>
            //               {movie.title}
            //            </a>
            //         </h3>
            //         <p className="mt-5 text-sm leading-6 text-gray-600">Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.</p>
            //      </div>
            //      <div className="mt-6 flex border-t border-gray-900/5 pt-6">
            //         <div className="relative flex items-center gap-x-4">
            //            <div className="text-sm leading-6">
            //               <p className="font-semibold text-gray-900">
            //                  <a href="#">
            //                     <span className="absolute inset-0"></span>
            //                     {movie.directors.find((director) => director !== "")}
            //                  </a>
            //               </p>
            //               <p className="text-gray-600">{movie.actors.join(", ")}</p>
            //            </div>
            //         </div>
            //      </div>
            //   </div>
            //</article>

            <article className="flex flex-col md:flex-row gap-8">
               <div className="relative isolate sm:w-64 sm:h-96 shrink-0">
                  <img src={imageUrl} alt="" className="aspect-square object-cover h-full w-full rounded-2xl" />
               </div>
               <div className="px-2 md:px-0">
                  <div className="flex flex-col sm:flex-row items-center gap-x-4 gap-y-2 text-sm">
                     <div>
                        <span className="font-semibold text-gray-900">Release year: </span>
                        <span className="text-gray-900">{movie.releaseYear}</span>
                     </div>                     
                     <div className="flex sm:flex-row gap-3 py-1.5">
                        {movie.genre.map(movieGenre => {
                           return (
                              <span className="rounded-full px-3 bg-gray-50 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                                 {movieGenre}
                              </span>
                           )
                        })}
                     </div>
                  </div>
                  <div className="group relative max-w-xl">
                     <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900">
                        {movie.title}
                     </h3>
                     <p className="mt-5 text-sm leading-6 text-gray-600">Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.</p>
                  </div>
                  <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                     <div className="relative flex items-center gap-x-4">
                        <div className="text-sm leading-6">
                           <div>
                              <span className="text-gray-600">{movie.directors.find((director) => director !== "")}</span>
                           </div>
                           <div>
                              <span className="text-gray-600">{movie.actors.join(", ")}</span>
                           </div>
                           <div className="flex flex-row gap-3 pt-4">
                              <div>
                                 <span className="font-semibold text-gray-900">Duration: </span>
                                 <span className="text-gray-900">{movie.duration}</span>
                              </div>
                              <div>
                                 <span className="font-semibold text-gray-900">Score: </span>
                                 <span className="text-gray-600">{movie.scoreRottenTomatoes}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </article>
         )}
      </>
   )
}

export default MovieDetails;