import { useCallback } from 'react';
import axios from 'axios';

const useAxiosHelper = () => {
   const getAsync = useCallback(async (url, useCache = false, cacheExpiry = 3600000) => {
      if (useCache) {
         const cachedData = localStorage.getItem(url);
         if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (!cacheExpiry || Date.now() - timestamp < cacheExpiry) {
               return data;
            }
         }
      }

      const result = await axios.get(url);
      if (useCache) {
         localStorage.setItem(url, JSON.stringify({ data: result.data, timestamp: Date.now() }));
      }
      return result.data;
   }, []);

   const postAsync = useCallback(async (url, data) => {
      const result = await axios.post(url, data);
      return result.data;
   }, []);

   const putAsync = useCallback(async (url, data) => {
      const result = await axios.put(url, data);
      return result.data;
   }, []);

   const deleteAsync = useCallback(async (url) => {
      const result = await axios.delete(url);
      return result.data;
   }, []);

   const clearCache = useCallback(() => {
      localStorage.clear();
   }, []);

   return { getAsync, postAsync, putAsync, deleteAsync, clearCache };
};

export default useAxiosHelper;