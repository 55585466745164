import axios from 'axios';

class AxiosHelper {
   async getAsync(url, useCache = false, cacheExpiry = 3600000) {
      if (useCache) {
         const cachedData = localStorage.getItem(url);
         if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (!cacheExpiry || Date.now() - timestamp < cacheExpiry) {
               return data;
            }
         }
      }

      const result = await axios.get(url);
      if (useCache) {
         localStorage.setItem(url, JSON.stringify({ data: result.data, timestamp: Date.now() }));
      }
      return result.data;
   }

   async postAsync(url, data) {
      const result = await axios.post(url, data);
      return result.data;
   }

   async putAsync(url, data) {
      const result = await axios.put(url, data);
      return result.data;
   }

   async deleteAsync(url) {
      const result = await axios.delete(url);
      return result.data;
   }

   clearCache() {
      localStorage.clear();
   }
}

export default new AxiosHelper();