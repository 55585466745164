import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from "../../constants/application-page";
import useAxiosHelper from '../customHooks/useAxiosHelper';
import useUploadImage from '../customHooks/useUploadImage';

function MovieCard({ movie }) {

   const navigate = useNavigate();
   const [imageUrl, setImageUrl] = useState(null);
   const { getAsync } = useAxiosHelper();
   const { downloadImage } = useUploadImage();

   useEffect(() => {
      const fetchImageUrl = async () => {
         try {
            // Get pre-signed url
            const preSignedUrl = await downloadImage(movie.id);
            setImageUrl(preSignedUrl);
         } catch (error) {
            console.error('Error fetching movie cover image:', error);
         }
      };

      fetchImageUrl();
   }, [movie.id, getAsync]);

   const handleClick = () => {
      navigate(`${Page.MOVIESPAGE}/${movie.id}`);
   }

   return (
      <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 text-center pb-3 pt-80">
         <img src={`${imageUrl}`} alt="" className="object-cover absolute inset-0 -z-10 h-full w-full" />
         <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
         <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
         <h3 className="text-lg font-semibold leading-6 text-white">
            <button onClick={handleClick}>
               <span className="absolute inset-0"></span>
               {movie.title}
            </button>
         </h3>
         {/*<div className="flex flex-row items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">*/}
         {/*   <div className="mr-8">{movie.releaseYear}</div>*/}
         {/*   <div className="flex items-center">{movie.directors.find((director) => director !== "")}</div>*/}
         {/*</div>*/}
         {/*<div className="flex flex-row items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">*/}
         {/*   <div className="mr-8">{movie.genre.join(", ")}</div>*/}
         {/*</div>*/}
         {/*<div className="flex flex-row items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">*/}
         {/*   <div className="mr-8">{movie.actors.join(", ")}</div>*/}
         {/*</div>*/}
         {/*<div className="flex flex-row items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">*/}
         {/*   <div className="mr-8">{movie.duration} mins</div>*/}
         {/*   <div className="flex items-center">Score: {movie.scoreRottenTomatoes}</div>*/}
         {/*</div>*/}
      </article>
   )
}

export default MovieCard;
