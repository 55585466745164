import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './components/pages/Home';
import Projects from './components/pages/Projects';
import Header from './components/Header';
import Footer from './components/Footer';
import Breadcrumbs from "./components/Breadcrumbs";

import Movies from './components/movies/Movies';
import MovieDetails from './components/movies/MovieDetails';
import MovieCreate from './components/movies/MovieCreate';

function App() {
   return (
      <BrowserRouter>
         <div className="flex flex-col min-h-screen w-full max-w-screen-lg mx-auto">
            <Header />
            <main className="flex-grow px-4 md:px-6 py-6">
               <Breadcrumbs />
               <div className="mx-auto max-w-screen-lg py-3 text-md">
                  <Routes>
                     <Route path="/" element={<Home />} />
                     <Route path="/projects" element={<Projects />} />
                     <Route path="/projects/movies" element={<Movies />} />
                     <Route path="/projects/movies/:id" element={<MovieDetails />} />
                     <Route path="/projects/movies/new" element={<MovieCreate />} />
                     {/* <Route path="/movies/:id?edit=true" element={<MovieDetails editMode={true} />} /> */}
                  </Routes>
               </div>
            </main>
            <Footer />
         </div>
      </BrowserRouter>
   );
}

export default App;