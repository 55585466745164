import React from 'react';
import { Field } from 'formik';

const FormikInput = ({ name, className = "" }) => {
   return (
      <div className={className}>
         <Field
            name={name}
            type="text"
            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
         />
      </div>
   );
};

export default FormikInput;
