import React, { useState } from 'react';

import { getMoviesUrl } from "../../helpers/url-helper";
import MovieForm from './MovieForm';
import useUploadImage from '../customHooks/useUploadImage';
import useAxiosHelper from '../customHooks/useAxiosHelper';

function MovieCreate() {
   const [isFinished, setIsFinished] = useState(false);
   const [error, setError] = useState(null);
   const { uploadImage, isUploading, uploadError } = useUploadImage();
   const { postAsync } = useAxiosHelper();

   const handleSubmit = async (values, selectedImage) => {
      try {
         const movieData = {
            ...values,
            genre: values.genre.split(', '),
            directors: values.directors.split(', '),
            actors: values.actors.split(', ')
         };

         const data = await postAsync(getMoviesUrl(), movieData);

         if (selectedImage) {
            await uploadImage(selectedImage, data.id);
         }

         setIsFinished(true);
      } catch (error) {
         setError(error);
         console.error('Error creating movie:', error);
      }
   };

   if (error || uploadError) {
      return <div>Error: {error || uploadError}</div>;
   }

   if (isFinished) {
      return <div>Movie Created Successfully</div>;
   }

   return <MovieForm onSubmit={handleSubmit} />;
}

export default MovieCreate;