import { useState } from 'react';
import axios from 'axios';
import { getFileExtension } from "../../helpers/content-type-helper";
import { getUploadImageUrl, getDownloadImageUrl } from "../../helpers/url-helper";
import AxiosHelper from "../../helpers/axios-helper";

const useUploadImage = () => {
   const [isUploading, setIsUploading] = useState(false);
   const [uploadError, setUploadError] = useState(null);

   const uploadImage = async (file, id) => {
      try {
         setIsUploading(true);
         setUploadError(null);

         const contentType = file.type;
         const extension = getFileExtension(contentType);
         const key = `${id}${extension}`;

         // Get pre-signed url
         const response = await AxiosHelper.getAsync(getUploadImageUrl(key, contentType));
         await axios.put(response.url, file, { headers: { 'Content-Type': contentType } });

         setIsUploading(false);
      } catch (error) {
         setUploadError(error);
         console.error('Error uploading image:', error);
      } finally {
         setIsUploading(false);
      }
   };

   const downloadImage = async (id) => {
      try {
         const response = await AxiosHelper.getAsync(getDownloadImageUrl(id), true);
         return response.url;
      } catch (error) {
         console.error('Error uploading image:', error);
      }
   };

   return { downloadImage, uploadImage, isUploading, uploadError };
};

export default useUploadImage;